const header = {
  element: document.querySelector('.hbot__hamburger'),
  body: document.querySelector('body'),

  toggleMenu: () => {
    header.element.classList.toggle('hbot__hamburger--active');
    header.body.classList.toggle('body-fixed');
  }
};

header.element.addEventListener('click', function () {
  header.toggleMenu();
});
